<template>
  <div>
    <div class="container-fluid">
      <div class="row">

        <div class="separador">
          <div class="animate__animated animate__backInLeft">
            <p class="white text-center pt-3" style="font-size: 35px">
              {{ texto }}
            </p>
            <p class="white text-center" style="font-size: 15px">
              Motureana Industrial
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["texto"],
  name: "separador",
  data() {
    return {};
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.separador {
  background-image: url('../assets/herosmall.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 150px;
  background-color: #343a3f;
}

.blue-stripe {
  background-color: #20469f;
  height: 6px;
}
</style>
