<template>
  <div>
    <main class="flex-shrink-0">

             <!-- Navigation-->
            <Menu :type='404' />

            <!-- Page Content-->
            <section class="py-5">
                <div class="container px-5 my-5">
                    <Back/>
                    
                    <Title :titulo = "f.titulo" :texto = "f.texto" />
                    
                    <div class="row gx-5">
                        <div class="col-12">
                          <img class="img-fluid rounded-3 mb-3" :src="getImg(f.img)" alt="..." />
                        </div>
                        <div class="col-lg-6">
                          <img class="img-fluid rounded-3 mb-3" :src="getImg(f.img2)" alt="..." />
                        </div>
                        <div class="col-lg-6">
                          <img class="img-fluid rounded-3 mb-3" :src="getImg(f.img3)" alt="..." />
                        </div>
                    </div>
                    
                    <div class="row gx-5">
                        <div class="col-lg-6">
                            <div class="mb-5">
                                <div v-if="f.texto2">
                                  <p class="lead fw-normal">{{f.texto2}}</p>
                                  <ul>
                                    <li v-for="i in f.lista" :key="i.item">{{i.item}}</li>
                                  </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-5">
                                <div v-if="f.texto3">
                                  <p class="lead fw-normal">{{f.texto3}}</p>
                                  <ul>
                                    <li v-for="i in f.lista2" :key="i.item">{{i.item}}</li>
                                  </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </main>
  </div>
</template>


<script>
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'
import Menu from '@/components/Menu'
import Back from '@/components/Back'
import Title from '@/components/Title'

export default {
name: 'Home',
mixins: [ globalFunctions ],
components:{ Menu, Back, Title },
data () {
  return {
    f: ''
  }
},
computed:{
  ...mapState(['url'])
},
methods: {
  getData(){
    
    this.f = {
                  titulo: 'Balanceo Dinámico en campo y con bases flotantes', 
                  texto: 'Un correcto balanceo dinámico prolonga la vida útil de los componentes mecánicos de su equipo garantizando su óptimo desempeño.',
                  texto2: 'Los efectos más comunes del desbalanceo de componentes rotativos son:',
                  lista: [
                          {'item':'Daños en Rodamiento y Chumaceras'},
                          {'item':'Daño en sellos y retenes'},
                          {'item':'Pérdida de ajustes mecánicos'},
                          {'item':'Mala cimentación de Equipo'},
                          {'item':'Deformacion a su figura original'},
                          {'item':'Mala precisión en maquinados de piezas'}
                         ],
                  texto3: 'Balanceo Dinámico a:',
                  lista2: [
                          {'item':'Rotores'},
                          {'item':'Ventiladores'},
                          {'item':'Turbinas'},
                         ],       
                  img:  'IMG_2952.jpeg',
                  img2: 'SERVICIO1.jpg',
                  img3: 'SERVICIO1-2.jpg'
                }

  },
  getImg(img){
    return require(`@/assets/${img}`);
  },
},
beforeMount(){
  window.scrollTo(0, 0);
  this.getData();
}

}
</script>


<style scoped>

.main_menu{
    background:/* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.5), 
          rgba(0, 0, 0, 0.5)
        ),
        url('../assets/hero.jpg');
    background-size: cover;
    position: relative;
    /*min-height: 110vh;*/
}


</style>