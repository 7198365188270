<template>
  <div>
    <main class="flex-shrink-0">

             <!-- Navigation-->
            <Menu :type='404' />

            <!-- Page Content-->
            <section class="py-5">
                <div class="container px-5 my-5">
                    <Back/>
                    
                    <Title :titulo = "f.titulo" :texto = "f.texto" />
                    
                    <div class="row gx-5">
                        <div class="col-6">
                          <img class="img-fluid rounded-3 mb-3" :src="getImg(f.img)" alt="..." />
                        </div>
                        <div class="col-lg-6">
                          <img class="img-fluid rounded-3 mb-3" :src="getImg(f.img2)" alt="..." />
                        </div>
                        <div class="col-lg-6">
                          <img class="img-fluid rounded-3 mb-3" :src="getImg(f.img3)" alt="..." />
                        </div>
                        <div class="col-lg-6">
                          <img class="img-fluid rounded-3 mb-3" :src="getImg(f.img4)" alt="..." />
                        </div>
                    </div>
                    
                </div>
            </section>
    </main>
  </div>
</template>


<script>
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'
import Menu from '@/components/Menu'
import Back from '@/components/Back'
import Title from '@/components/Title'

export default {
name: 'Home',
mixins: [ globalFunctions ],
components:{ Menu, Back, Title },
data () {
  return {
    f: ''
  }
},
computed:{
  ...mapState(['url'])
},
methods: {
  getData(){
    
   this.f = {
              titulo: 'Fabricación de Flechas', 
              texto: 'Una Flecha es la que se encarga de transmitir potencia entre dos o más elementos dentro de una máquina, también conocida como eje de transmisión. Si requiere una cotización de flecha, por favor envíenos dibujos y/o planos para que podamos elaborar su cotización lo más pronto posible. Si no tiene lo anterior contamos con el área de Diseño e Ingenieria para ofrecerle servicio Técnico.',
              texto2: '',
              img:  'servicio4-1.jpeg',
              img2: 'servicio4-2.jpeg',
              img3: 'servicio4-3.jpeg',
              img4: 'servicio4-4.jpeg'
            }
  },

  getImg(img){
    return require(`@/assets/${img}`);
  },

},
beforeMount(){
  window.scrollTo(0, 0);
  this.getData();
}

}
</script>


<style scoped>

.main_menu{
    background:/* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.5), 
          rgba(0, 0, 0, 0.5)
        ),
        url('../assets/hero.jpg');
    background-size: cover;
    position: relative;
    /*min-height: 110vh;*/
}


</style>