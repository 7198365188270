<template>
    
   <div class="pt-5 row gx-5 justify-content-center">
        <div class="col-lg-6">
            <div class="text-center mb-5">
                <h1 class="fw-bolder">{{titulo}}</h1>
                <p class="lead fw-normal text-muted mb-0">
                    {{texto}}
                </p>
            </div>
        </div>
    </div>

</template>

<script>
export default {
  name: "separador",
  props: ["titulo","texto"],
  data() {
    return {};
  },
};
</script>


<style scoped>

</style>
