<template>
  <div>
   <main class="flex-shrink-0">
            <!-- Navigation-->
            <Menu :type='1' />
            
            <!--Sub Menu-->
            <Separador texto = 'Nosotros' regresar = '0'/>

            <!-- Header-->
            <header class="py-5">
                <div class="container px-5">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-xxl-6">
                            <div class="text-center my-5">
                                <h1 class="fw-bolder mb-3">Quienes Somos</h1>
                                <p class="lead fw-normal text-muted mb-4">
                                    MONTUREANA INDUSTRIAL es una empresa creada en el año 2018 para ofrecer servicio de balanceo dinámico en campo y en bases flotantes, 
                                    así como también ofrecer análisis de vibración en rodamiento de infinidad de equipos rotativos.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            
            <!-- About section one-->
            <section class="py-5 bg-light" id="scroll-target">
                <div class="container px-5 my-5">
                    <div class="row gx-5 align-items-center">
                        <div class="col-lg-6"><img class="img-fluid rounded mb-5 mb-lg-0" src="../assets/IMG_2960.jpeg" alt="..." /></div>
                        <div class="col-lg-6">
                            <h2 class="fw-bolder">Mision</h2>
                            <p class="lead fw-normal text-muted mb-0">
                                Motureana Industrial tiene como prioridad satisfacer las necesidades del cliente ofreciendo asistencia técnica integral
                                basada en la confiabilidad de la alta tecnología que maneja en sus equipos de medición.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <!-- About section two-->
            <section class="py-5">
                <div class="container px-5 my-5">
                    <div class="row gx-5 align-items-center">
                        <div class="col-lg-6 order-first order-lg-last"><img class="img-fluid rounded mb-5 mb-lg-0" src="../assets/hero.jpg" alt="..." /></div>
                        <div class="col-lg-6">
                            <h2 class="fw-bolder">Vision</h2>
                            <p class="lead fw-normal text-muted mb-0">
                                Ser una empresa reconocida a nivel nacional en la solución de análisis de vibración y balanceo dinámico.                                
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="py-5 bg-light">
                <div class="container px-5 my-5">
                    <div class="text-center">
                        <h2 class="fw-bolder">Valores</h2>
                        <!--<p class="lead fw-normal text-muted mb-5">Lo que nos define</p>-->
                        <br><br>
                    </div>

                    <div class="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-3 justify-content-center">
                        <div class="col mb-5 mb-5 mb-xl-0">
                            <div class="text-center">
                                <h5 class="fw-bolder">Respeto</h5>
                            </div>
                        </div>
                        <div class="col mb-5 mb-5 mb-xl-0">
                            <div class="text-center">
                                <h5 class="fw-bolder">Confiabilidad</h5>
                            </div>
                        </div>
                        <div class="col mb-5 mb-5 mb-xl-0">
                            <div class="text-center">
                                <h5 class="fw-bolder">Compromiso</h5>
                            </div>
                        </div>
                        <div class="col mb-5 mb-5 mb-xl-0">
                            <div class="text-center">
                                <h5 class="fw-bolder">Ética Profesional</h5>
                            </div>
                        </div>
                        <div class="col mb-5 mb-5 mb-xl-0">
                            <div class="text-center">
                                <h5 class="fw-bolder">Honestidad</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Team members section
            <section class="py-5">
                <div class="container px-5 my-5">
                    <div class="text-center">
                        <h2 class="fw-bolder">Nosotros</h2>
                        <p class="lead fw-normal text-muted mb-5">Titulo</p>
                    </div>
                    <div class="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-center">
                        <div class="col mb-5 mb-5 mb-xl-0">
                            <div class="text-center">
                                <img class="img-nosotros mb-4" src="../assets/team.jpg" alt="..." />
                                <h5 class="fw-bolder">Alejandro Macias</h5>
                                <div class="fst-italic text-muted">Titulo</div>
                            </div>
                        </div>
                        <div class="col mb-5 mb-5 mb-xl-0">
                            <div class="text-center">
                                <img class="img-nosotros mb-4" src="../assets/team.jpg" alt="..." />
                                <h5 class="fw-bolder">Alejandro Macias</h5>
                                <div class="fst-italic text-muted">Titulo</div>
                            </div>
                        </div>
                        <div class="col mb-5 mb-5 mb-xl-0">
                            <div class="text-center">
                                <img class="img-nosotros mb-4" src="../assets/team.jpg" alt="..." />
                                <h5 class="fw-bolder">Alejandro Macias</h5>
                                <div class="fst-italic text-muted">Titulo</div>
                            </div>
                        </div>
                        <div class="col mb-5 mb-5 mb-xl-0">
                            <div class="text-center">
                                <img class="img-nosotros mb-4" src="../assets/team.jpg" alt="..." />
                                <h5 class="fw-bolder">Alejandro Macias</h5>
                                <div class="fst-italic text-muted">Titulo</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> -->
        </main>
  </div>
</template>


<script>
import { globalFunctions } from '@/hooks/globalFunctions'
import Menu from '@/components/Menu'
import Separador from '@/components/Separador'
import { mapState } from 'vuex'

export default {
name: 'Home',
components:{ Menu, Separador },
mixins: [ globalFunctions ],
data () {
  return {
    
  }
},
computed:{
  ...mapState(['url'])
},
methods: {
  
},
mounted(){
  window.scrollTo(0, 0);
}

}
</script>


<style scoped>

.img-nosotros{
    border-radius: 50%;
    height: 150px;
    width: 150px;
}


</style>