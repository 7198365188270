import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Nosotros from '../views/Nosotros'
import Contacto from '../views/Contacto'
import Servicios from '../views/Servicios'
import Detalle from '../views/Detalle'
import Servicio1 from '../views/Servicio1'
import Servicio2 from '../views/Servicio2'
import Servicio3 from '../views/Servicio3'
import Servicio4 from '../views/Servicio4'
import Servicio5 from '../views/Servicio5'
import Servicio6 from '../views/Servicio6'
import Servicio7 from '../views/Servicio7'
import Servicio8 from '../views/Servicio8'

const routes = [
  /*{
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },*/
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Nosotros',
    name: 'Nosotros',
    component: Nosotros
  },
  {
    path: '/Contacto',
    name: 'Contacto',
    component: Contacto
  },
  {
    path: '/Servicios',
    name: 'Servicios',
    component: Servicios
  },
  {
    path: '/Detalle/:id/:title',
    name: 'Detalle',
    component: Detalle
  },
  {
    path: '/Servicio1',
    name: 'Servicio1',
    component: Servicio1
  },
  {
    path: '/Servicio2',
    name: 'Servicio2',
    component: Servicio2
  },
  {
    path: '/Servicio3',
    name: 'Servicio3',
    component: Servicio3
  },
  {
    path: '/Servicio4',
    name: 'Servicio4',
    component: Servicio4
  },
  {
    path: '/Servicio5',
    name: 'Servicio5',
    component: Servicio5
  },
  {
    path: '/Servicio6',
    name: 'Servicio6',
    component: Servicio6
  },
  {
    path: '/Servicio7',
    name: 'Servicio7',
    component: Servicio7
  },
  {
    path: '/Servicio8',
    name: 'Servicio8',
    component: Servicio8
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
