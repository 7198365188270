<template>
  <div>
    <main class="flex-shrink-0">

             <!-- Navigation-->
        <Menu :type='404' />

        <!-- Page Content-->
        <section class="py-5">
            <div class="container px-5 my-5">
              <Back/>
                
              <Title :titulo = "f.titulo" :texto = "f.texto" />
                
              <div class="row gx-5">
                  <div class="col-6">
                    <div class="mb-5">
                          <div v-if="f.texto2">
                            <p class="lead fw-normal">{{f.texto2}}</p>
                            <ul>
                              <li v-for="i in f.lista" :key="i.item">{{i.item}}</li>
                            </ul>
                          </div>
                      </div>
                  </div>
                  <div class="col-6">
                    <img class="img-fluid rounded-3 mb-3" :src="getImg(f.img)" alt="..." />
                  </div>
              </div>
            
            </div>
        </section>
    </main>
  </div>
</template>


<script>
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'
import Menu from '@/components/Menu'
import Back from '@/components/Back'
import Title from '@/components/Title'

export default {
name: 'Home',
mixins: [ globalFunctions ],
components:{ Menu, Back, Title },
data () {
  return {
    f: ''
  }
},
computed:{
  ...mapState(['url'])
},
methods: {
  getData(){
    
    this.f = {
                  titulo: 'Diagnóstico de fallas mediante análisis de vibración', 
                  texto: 'Con el equipo en marcha, es muy difícil evitar las vibraciones, ya que suelen surgir por defectos dínamicos de tolerancias de fabricación, contacto por rodadura y fricción, holguras y una infinidad de causas.',
                  texto2: 'Análisis de Vibración a:',
                  lista: [
                          {'item':'Rodamiento de motores'},
                          {'item':'Equipos con rodamiento de chumaceras'},
                          {'item':'Bombas'}
                         ],
                  img: 'SERVICIO2-1.png'
                }

  },
  getImg(img){
    return require(`@/assets/${img}`);
  },
},
beforeMount(){
  window.scrollTo(0, 0);
  this.getData();
}

}
</script>


<style scoped>

.main_menu{
    background:/* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.5), 
          rgba(0, 0, 0, 0.5)
        ),
        url('../assets/hero.jpg');
    background-size: cover;
    position: relative;
    /*min-height: 110vh;*/
}


</style>