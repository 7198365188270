<template>
  <div>
    
    <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom border-5 border-danger">
      <div class="container-fluid">

        <router-link class="navbar-brand" to="/">
          <img src="../assets/logo.png" class="img_logo" alt="" height="60" />
        </router-link>
        
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mt-3 mb-lg-0">
            <li class="nav-item">
                <p class="text-center white">
                  <router-link class="nav-link rounded-2" to="/" :class="{notActive: type != 0,linkActive: type == 0}">
                      Inicio
                  </router-link>
                </p>
            </li>
            <li class="nav-item">
                <p class="text-center white">
                  <router-link class="nav-link rounded-2" to="/Nosotros" :class="{notActive: type != 1,linkActive: type == 1}">
                    Nosotros
                  </router-link>
                </p>
            </li>
            <li class="nav-item">
                <p class="text-center white">
                  <router-link class="nav-link rounded-2" to="/Servicios" :class="{notActive: type != 2,linkActive: type == 2}">
                    Servicios
                  </router-link>
                </p>
                
            </li>
            <li class="nav-item">
                <p class="text-center white">
                  <router-link class="nav-link rounded-2" to="/Contacto" :class="{notActive: type != 3,linkActive: type == 3}">
                    Contacto
                  </router-link>
                </p>
                
            </li>
          </ul>
        </div>

      </div>
    </nav>

    <div class="container-fluid menu-contacto">
      <div class="row">
        <div class="col-6 white px-5 pt-3">
          <a class="link" href="#">
            <i class="bi bi-telephone-fill"></i> 
            <span class="hide_phone"> 449 413 9944 </span>
          </a>
        </div>
        <div class="col-6 white">
          <p class="zfloat-end px-5 pt-3 text-end">
            <a class="link" target="blank" href="https://api.whatsapp.com/send?phone=524494139944&text=Me%20interesa%20agendar%20una%20cita.">
              <span class="hide_phone">
                Whatsapp
                <i class="bi bi-whatsapp"></i>
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: ["type"],
  data() {
    return {};
  },
  methods: {},
};
</script>


<style scoped>
  .linkActive{
    font-weight: bold;
    color: black;
    font-size: 16px;
  }
  .menu-contacto {
    background-color: black;
  }
</style>