<template>
  <div>
    <main class="flex-shrink-0">

             <!-- Navigation-->
            <Menu :type='404' />

            <!-- Page Content-->
            <section class="py-5">
                <div class="container px-5 my-5">
                    <div class="row gx-5 justify-content-center">
                        <div class="col-lg-6">
                            <div class="text-center mb-5">
                                <h1 class="fw-bolder">{{title}}</h1>
                                <p class="lead fw-normal text-muted mb-0">
                                  {{f.texto}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row gx-5">
                        <div class="col-12">
                          <img class="img-fluid rounded-3 mb-3" :src="getImg(f.img)" alt="..." />
                        </div>
                        <div class="col-lg-6">
                          <img class="img-fluid rounded-3 mb-3" :src="getImg(f.img2)" alt="..." />
                        </div>
                        <div class="col-lg-6">
                          <img class="img-fluid rounded-3 mb-3" :src="getImg(f.img3)" alt="..." />
                        </div>
                    </div>
                    <div class="row gx-5">
                        <div class="col-lg-6">
                            <div class="mb-5">
                                <div v-if="f.texto2">
                                  <p class="lead fw-normal">{{f.texto2}}</p>
                                  <ul>
                                    <li v-for="i in f.lista" :key="i.item">{{i.item}}</li>
                                  </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-5">
                                <div v-if="f.texto3">
                                  <p class="lead fw-normal">{{f.texto3}}</p>
                                  <ul>
                                    <li v-for="i in f.lista2" :key="i.item">{{i.item}}</li>
                                  </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </main>
  </div>
</template>


<script>
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'
import Menu from '@/components/Menu'

export default {
name: 'Home',
mixins: [ globalFunctions ],
components:{ Menu },
data () {
  return {
    objeto: this.$route.params.id,
    title: this.$route.params.title,
    f: ''
  }
},
computed:{
  ...mapState(['url'])
},
methods: {
  getData(){
    
    switch (this.objeto) {
      case '1':
        this.f = {
                  titulo: 'Titulo del Proyecto 1', 
                  texto: 'Un correcto balanceo dinámico prolonga la vida útil de los componentes mecánicos de su equipo garantizando su óptimo desempeño.',
                  texto2: 'Los efectos más comunes del desbalanceo de componentes rotativos son:',
                  lista: [
                          {'item':'Daños en Rodamiento y Chumaceras'},
                          {'item':'Daño en sellos y retenes'},
                          {'item':'Pérdida de ajustes mecánicos'},
                          {'item':'Mala cimentación de Equipo'},
                          {'item':'Deformacion a su figura original'},
                          {'item':'Mala precisión en maquinados de piezas'}
                         ],
                  texto3: 'Balanceo Dinámico a:',
                  lista2: [
                          {'item':'Rotores'},
                          {'item':'Ventiladores'},
                          {'item':'Turbinas'},
                          
                         ],       
                  img: 'IMG_2952.jpeg',
                  img2: 'IMG_2952.jpeg',
                  img3: 'IMG_2955.jpeg'
                }
        break;
      case '2':
         this.f = {
                  titulo: 'Titulo del Proyecto 2', 
                  texto: 'Con el equipo en marcha, es muy difícil evitar las vibraciones, ya que suelen surgir por defectos dínamicos de tolerancias de fabricación, contacto por rodadura y fricción, holguras y una infinidad de causas.',
                  texto2: 'Análisis de Vibración a:',
                  lista: [
                          {'item':'Rodamiento de motores'},
                          {'item':'Equipos con rodamiento de chumaceras'},
                          {'item':'Bombas'}
                         ],
                  img: 'IMG_2961.jpeg',
                  img2: 'IMG_2962.jpeg',
                  img3: 'IMG_2962.jpeg'
                }
        break;
      case '3':
        this.f = {
                titulo: 'Titulo del Proyecto 3', 
                texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab similique, ducimus ut alias sit accusamus illum, asperiores deserunt dolorum quaerat qui! Ab, quisquam explicabo magni dolores unde beatae quam a.',
                texto2: '',
                img: 'IMG_2957.jpeg',
                img2: 'IMG_2959.jpeg',
                img3: 'IMG_2960.jpeg'
              }
      break;
    }

  },
  getImg(img){
    return require(`@/assets/${img}`);;
  },
},
beforeMount(){
  window.scrollTo(0, 0);
  this.getData();
}

}
</script>


<style scoped>

.main_menu{
    background:/* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.5), 
          rgba(0, 0, 0, 0.5)
        ),
        url('../assets/hero.jpg');
    background-size: cover;
    position: relative;
    /*min-height: 110vh;*/
}


</style>