<template>
  <div>
  <main class="flex-shrink-0">
            
            <!-- Navigation-->
            <Menu :type='3' />
            
            <!--Sub Menu-->
            <Separador texto = 'Contacto' regresar = '0'/>
            
            <!-- Page content-->
            <section class="py-5">
                <div class="container px-5">
                    <!-- Contact form-->
                    <div class="rounded-3 py-5 px-4 px-md-5 mb-5">
                        <div class="text-center mb-5">
                            <div class="feature color-primario bg-gradient text-white rounded-3 mb-3"><i class="bi bi-envelope"></i></div>
                            <h1 class="fw-bolder">Mandanos un mensaje</h1>
                            <p class="lead fw-normal text-muted mb-0">Nos gustaría escuchar de ti</p>
                        </div>
                        <div class="row gx-5 justify-content-center">
                            <div class="col-lg-8 col-xl-6">
                                <form @submit="sendEmail">
                                    <!-- Name input-->
                                    <div class="form-floating mb-3">
                                        <input class="form-control" id="name" v-model="nombre" type="text" required placeholder="Enter your name..." />
                                        <label for="name">Nombre</label>
                                    </div>
                                    
                                    <!-- Email address input-->
                                    <div class="form-floating mb-3">
                                        <input class="form-control" v-model="correo" type="email" required placeholder="name@example.com" />
                                        <label for="email">Correo</label>
                                    </div>
                                    
                                    <!-- Phone number input-->
                                    <div class="form-floating mb-3">
                                        <input class="form-control" id="phone" type="tel" v-model="telefono" required placeholder="(123) 456-7890" />
                                        <label for="phone">Numero de Tel</label>
                                    </div>
                                    
                                    <!-- Message input-->
                                    <div class="form-floating mb-3">
                                        <textarea class="form-control" id="message" v-model="cuerpo" required placeholder="Enter your message here..." style="height: 10rem"></textarea>
                                        <label for="message">Mensaje</label>
                                    </div>
                                    
                                    <!-- Submit Button-->
                                    <div class="d-grid"><button class="btn btn-primary btn-lg" id="submitButton" type="submit">{{enviando}}</button></div>
                                </form>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </main>
  </div>
</template>

<script>
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'
import Menu from '@/components/Menu'
import Separador from '@/components/Separador'

export default {
name: 'Home',
mixins: [ globalFunctions ],
components:{ Menu, Separador },
data () {
  return {
    nombre: '',
    correo: '',
    telefono: '',
    cuerpo: '',
    enviando: 'Enviar'
  }
},
computed:{
  ...mapState(['url'])
},
methods: {
  sendEmail(e){
    e.preventDefault();

    let data ={
        flag: 0,
        nombre: this.nombre,
        correo: this.correo,
        telefono: this.telefono,
        cuerpo: this.cuerpo
    }

    this.enviando = "Enviando...";
    
    this.axios.post(this.url + 'sendEmail.php', data).then((responce) =>{
        this.enviando = "Enviar";
        if(responce.data == '200'){
            alert("Correo Enviado con exito");
            this.nombre = "";
            this.correo = "";
            this.telefono = "";
            this.cuerpo = "";
        }else{
            console.log(responce.data);
            alert("Error al tratar de enviar correo");
        }
    });
  }
},
mounted(){
  window.scrollTo(0, 0);
}

}
</script>


<style scoped>

</style>