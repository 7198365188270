<template>
  <div>
       <main class="flex-shrink-0">
            <!-- Navigation-->
            <Menu :type='2' />

            <!--Sub Menu-->
            <Separador texto = 'Servicios' regresar = '0'/>

            <!-- Page Content-->
            <section class="py-5">
                <div class="container px-5 my-5">
                    <div class="text-center mb-5">
                        <h1 class="fw-bolder">Nuestros Servicios</h1>
                    </div>
                    <div class="row gx-5">
                        <div class="col-lg-6" v-for="f in datos" :key="f.id">
                            <div class="position-relative mb-5">
                                <img class="cuadro img-fluid shadow rounded-3 mb-3" :src="getImg(f.img)"  alt="..." />
                                <router-link class="h3 fw-bolder text-decoration-none link-dark stretched-link" :to="{ name: f.ruta }">
                                  {{f.titulo}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section class="py-1">
                <div class="container px-5 my-5">
                    <hr>
                </div>
            </section>

            <section class="">
                <div class="container px-5 my-5">
                    <h4 class="display-4 fw-bolder text-center mb-4">Contamos con maquinaría tales como:</h4>
                    <ul class="text-center" style="font-size: 28px">
                      <p>Torno Convencional</p>
                      <p>Fresadora</p>
                      <p>Torno CNC</p>
                      <p>Centro de Maquinados</p>
                    </ul> 
                </div>
            </section>

            <section class="py-5 bg-light">
                <div class="container px-5 my-5">
                    <h2 class="display-4 fw-bolder mb-4">Cuentanos tus inquietudes</h2>
                    <router-link class="btn btn-lg color-primario" to="/Contacto">Contactanos</router-link>
                </div>
            </section>
        </main>
  </div>
</template>


<script>
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'
import Menu from '@/components/Menu'
import Separador from '@/components/Separador'

export default {
name: 'Home',
mixins: [ globalFunctions ],
components:{ Menu, Separador },
data () {
  return {
    datos:[
            {
              'id': 1,
              'titulo': 'Balanceo Dinámico en campo y con bases flotantes',
              'img': 'IMG_2953.jpeg',
              'ruta': 'Servicio1'
            },
            {
              'id': 2,
              'titulo': 'Diagnóstico de fallas mediante análisis de vibración',
              'img': 'SERVICIO2-1.png',
              'ruta': 'Servicio2'
            },
            {
              'id': 3,
              'titulo': 'Diseño y Fabricación de Turbinas',
              'img': 'SERVICIO3-3.jpg',
              'ruta': 'Servicio3'
            },
            {
              'id': 4,
              'titulo': 'Fabricación de Flechas',
              'img': 'IMG_2958.jpeg',
              'ruta': 'Servicio4'
            },
            {
              'id': 5,
              'titulo': 'Alineación de Poleas',
              'img': 'servicio5-1.jpg',
              'ruta': 'Servicio5'
            },
            {
              'id': 6,
              'titulo': 'Proyectos de Automatización y control',
              'img': 'servicio6-2.jpeg',
              'ruta': 'Servicio7'
            },
            {
              'id': 7,
              'titulo': 'Servicios de Maquinados',
              'img': 'servicio8-1.jpeg',
              'ruta': 'Servicio8'
            }

        ],
        //ruta: '../src/assets/'
  }
},
computed:{
  ...mapState(['url'])
},
methods: {
  getImg(img){
    return require(`@/assets/${img}`);
  },
  abrirGaleria(f){
    alert(f)
  }
},
mounted(){
  window.scrollTo(0, 0);
}

}
</script>


<style scoped>

.main_menu{
    background:/* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.5), 
          rgba(0, 0, 0, 0.5)
        ),
        url('../assets/herosmall.jpg');
    background-size: cover;
    position: relative;
    /*min-height: 110vh;*/
}

.cuadro{
  height: 450px;
  width: auto;
}


</style>