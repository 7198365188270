<template>
  <div>
    <main class="flex-shrink-0">

             <!-- Navigation-->
            <Menu :type='404' />

            <!-- Page Content-->
            <section class="py-5">
                <div class="container px-5 my-5">
                    <Back/>
                    
                    <Title :titulo = "f.titulo" :texto = "f.texto" />
                    
                    <div class="row gx-5">
                        <div class="col-12">
                          <img class="img-fluid rounded-3 mb-3" :src="getImg(f.img)" alt="..." />
                        </div>
                        <div class="col-lg-6">
                          <img class="img-fluid rounded-3 mb-3" :src="getImg(f.img2)" alt="..." />
                        </div>
                        <div class="col-lg-6">
                          <img class="img-fluid rounded-3 mb-3" :src="getImg(f.img3)" alt="..." />
                        </div>
                    </div>
                    
                </div>
            </section>
    </main>
  </div>
</template>


<script>
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'
import Menu from '@/components/Menu'
import Back from '@/components/Back'
import Title from '@/components/Title'

export default {
name: 'Home',
mixins: [ globalFunctions ],
components:{ Menu, Back, Title },
data () {
  return {
    f: ''
  }
},
computed:{
  ...mapState(['url'])
},
methods: {
  getData(){
    
   this.f = {
              titulo: 'Diseño y Fabricación de Turbias', 
              texto: 'Fabricación y Diseño diferentes tipos de extractores de acuerdo a las necesidades de los clientes.',
              texto2: '',
              img:  'SERVICIO3-3.jpg',
              img2: 'SERVICIO3-2.jpg',
              img3: 'SERVICIO3-1.jpg'
            }
  },

  getImg(img){
    return require(`@/assets/${img}`);
  },

},
beforeMount(){
  window.scrollTo(0, 0);
  this.getData();
}

}
</script>


<style scoped>

.main_menu{
    background:/* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.5), 
          rgba(0, 0, 0, 0.5)
        ),
        url('../assets/hero.jpg');
    background-size: cover;
    position: relative;
    /*min-height: 110vh;*/
}


</style>