<template>
  <div>
    <main class="flex-shrink-0">
            <!-- Navigation-->
            <Menu :type='0' />
            
            <!-- Header-->
            <header class="bg-dark py-3 main_menu">
                <div class="container px-5">
                    <div class="row gx-5">
                        <div class="col-lg-8 col-xl-7 col-xxl-6">
                            <div class="my-5 text-center text-xl-start" style="position:relative; top:150px">
                                <h1 class="display-5 fw-bolder text-white mb-2">Análisis de vibraciones, balanceo dinámico y alineación</h1>
                                <p class="lead fw-normal text-white-50 mb-4">MONTUREANA INDUSTRIAL</p>
                                <div class="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                                    <!--<a class="btn color-primario btn-lg px-4 me-sm-3" href="#">
                                       Ver Más <i class="bi bi-play-circle"></i>
                                    </a>-->
                                    <a class="btn btn-outline-light btn-lg px-4" href="https://api.whatsapp.com/send?phone=524494139944&text=Me%20interesa%20agendar%20una%20cita.">
                                        Contactar <i style="color: #00a883" class="bi bi-whatsapp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!--<div class="col-xl-5 col-xxl-6 d-none d-xl-block text-center"><img class="img-fluid rounded-3 my-5" src="../assets/hero.jpg" alt="..." /></div>-->
                    </div>
                </div>
            </header>
            
            <!-- Features section-->
            <section class="py-5" id="features">
                <div class="container px-5 my-5">
                    <div class="row gx-5">
                        <div class="col-lg-4 mb-5 mb-lg-0"><h2 class="fw-bolder mb-0">Beneficios</h2></div>
                        <div class="col-lg-8">
                            <div class="row gx-5 row-cols-1 row-cols-md-2">
                                <div class="col mb-5 h-100">
                                    <div class="feature color-primario bg-gradient text-white rounded-3 mb-3"><i class="bi bi-cash-coin"></i></div>
                                    <h2 class="h5">Cotiza sin compromiso</h2>
                                    <p class="mb-0">Pide tu cotización sin compromiso del servicio requerido.</p>
                                </div>
                                <div class="col mb-5 h-100">
                                    <div class="feature color-primario bg-gradient text-white rounded-3 mb-3"><i class="bi bi-speedometer"></i></div>
                                    <h2 class="h5">Mejora la vida útil de tus equipos</h2>
                                    <p class="mb-0">
                                        Dar un mantenimiento predectivo y preventivo, mejora la vida útil de los equipos, por eso es muy importante
                                        saber las condiciones en que está trabajando el equipo ó maquinaría.
                                    </p>
                                </div>
                                <div class="col mb-5 mb-md-0 h-100">
                                    <div class="feature color-primario bg-gradient text-white rounded-3 mb-3"><i class="bi bi-globe"></i></div>
                                    <h2 class="h5">Únicos en la zona centro</h2>
                                    <p class="mb-0">MOTUREANA INDUSTRIAL es el único que ofrece servicios de Balanceo Dinámico y Análisis de Vibración en el centro del país con equipo de calidad y 
                                        precisos en las mediciones. </p>
                                </div>
                                <div class="col h-100">
                                    <div class="feature color-primario bg-gradient text-white rounded-3 mb-3"><i class="bi bi-tools"></i></div>
                                    <h2 class="h5">Asistencia Técnica.</h2>
                                    <p class="mb-0">Contamos con personal capacitado para realizar un levantamiento Técnico de acuerdo a la necesidad del cliente.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Testimonial section-->
            <div class="py-5 bg-light">
                <div class="container px-5 my-5">
                    <div class="row gx-5 justify-content-center">
                        <div class="col-lg-10 col-xl-7">
                            <div class="text-center">
                                <div class="fs-4 mb-4 fst-italic">
                                    MONTUREANA INDUSTRIAL es una empresa creada en el año 2018 para ofrecer servicio de balanceo dinámico en campo y en bases flotantes, 
                                    así como también ofrecer análisis de vibración en rodamiento de infinidad de equipos rotativos."
                                </div>
                                <!--<div class="d-flex align-items-center justify-content-center">
                                    <img class="rounded-circle me-3" src="https://dummyimage.com/40x40/ced4da/6c757d" alt="..." />
                                    <div class="fw-bold">
                                        Oscar Roque
                                        <span class="fw-bold text-primary mx-1">/</span>
                                        Dueño
                                    </div>
                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Blog preview section-->
            <section class="py-5">
                <div class="container px-5 my-5">
                    <div class="row gx-5 justify-content-center">
                        <div class="col-lg-8 col-xl-6">
                            <div class="text-center">
                                <h2 class="fw-bolder">Servicios</h2>
                                <p class="lead fw-normal text-muted mb-5">Conoce algunos de los servicios que tenemos para ti</p>
                            </div>
                        </div>
                    </div>
                    <div class="row gx-5">
                        <div class="col-lg-4 mb-5">
                            <div class="card h-100 shadow border-0">
                                <img class="card-img-top fotos" src="../assets/IMG_2952.jpeg" alt="..." />
                                <div class="card-body p-4">
                                    <!--<div class="badge bg-primary bg-gradient rounded-pill mb-2">Nuevo</div>-->
                                    <a class="text-decoration-none link-dark stretched-link" href="#!"><h5 class="card-title mb-3">Balanceo Dinámico en campo y con bases flotantes</h5></a>
                                    <p class="card-text mb-0">Prolonga la vida útil de los componentes mécanicos de su equipo.</p>
                                </div>
                                <div class="card-footer p-4 pt-0 bg-transparent border-top-0">
                                    <div class="d-flex align-items-end justify-content-between">
                                        <div class="d-flex align-items-center">
                                          <div class="small">
                                              <div class="fw-bold">
                                                <router-link class="text-decoration-none stretched-link" :to="{ name: 'Servicio1'}">
                                                    Ver Más
                                                </router-link>
                                              </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-5">
                            <div class="card h-100 shadow border-0">
                                <img class="card-img-top fotos" src="../assets/SERVICIO2-1.png" alt="..." />
                                <div class="card-body p-4">
                                    <a class="text-decoration-none link-dark stretched-link" href="#!"><h5 class="card-title mb-3">Diagnóstico de fallas mediante análisis de vibración</h5></a>
                                    <p class="card-text mb-0">Rodamiento de motores, equipos con rodamiento de chumaceras...</p>
                                </div>
                                <div class="card-footer p-4 pt-0 bg-transparent border-top-0">
                                    <div class="d-flex align-items-end justify-content-between">
                                        <div class="d-flex align-items-center">
                                          <div class="small">
                                              <div class="fw-bold">
                                                <router-link class="text-decoration-none stretched-link" :to="{ name: 'Servicio2'}">
                                                    Ver Más
                                                </router-link>
                                              </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-5">
                            <div class="card h-100 shadow border-0">
                                <img class="card-img-top fotos" src="../assets/SERVICIO3-3.jpg" alt="..." />
                                <div class="card-body p-4">
                                    <div class="badge bg-primary bg-gradient rounded-pill mb-2"></div>
                                    <a class="text-decoration-none link-dark stretched-link" href="#!"><h5 class="card-title mb-3">Diseño y Fabricación turbinas</h5></a>
                                    <p class="card-text mb-0">
                                        Fabricación y Diseño diferentes tipos de extractores de acuerdo a las necesidades de los clientes.
                                    </p>
                                </div>
                                <div class="card-footer p-4 pt-0 bg-transparent border-top-0">
                                    <div class="d-flex align-items-end justify-content-between">
                                        <div class="d-flex align-items-center">
                                          <div class="small">
                                              <div class="fw-bold">
                                                <router-link class="text-decoration-none stretched-link" :to="{ name: 'Servicio3'}">
                                                    Ver Más
                                                </router-link>
                                              </div>
                                              
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <p class="text-center">
                    <router-link to="/Servicios" style="font-size:25px; text-decoration: none;">Ver Todos</router-link>
                </p>
                
            </section>
    </main>
  </div>
</template>


<script>
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'
import Menu from '@/components/Menu'

export default {
name: 'Home',
mixins: [ globalFunctions ],
components:{ Menu },
data () {
  return {
    
  }
},
computed:{
  ...mapState(['url'])
},
methods: {
  
},
mounted(){
  window.scrollTo(0, 0);
}

}
</script>


<style scoped>

.main_menu{
    background:
        linear-gradient(
          rgba(0, 0, 0, 0), 
          rgba(0, 0, 0, 0)
        ),
        url('../assets/hero.jpg');
    background-size: cover;
    position: relative;
    min-height: 70vh;
}

.fotos{
    height: 240px;
}

</style>