<template>
  <footer class="bg-dark py-4 mt-auto">
    <div class="container px-5">
      <div class="row align-items-center justify-content-between flex-column flex-sm-row">
        <div class="col-auto">
          <div class="small m-0 text-white">
            Copyright &copy; Pagina Web 2022
          </div>
        </div>
        <div class="col-auto">
          <a class="link-light small" href="#">Privacidad</a>
          <span class="text-white mx-1">&middot;</span>
          <a class="link-light small" href="#">Terminos</a>
          <span class="text-white mx-1">&middot;</span>
          <a class="link-light small" href="#">Contact</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { globalFunctions } from "@/hooks/globalFunctions";
import { mapState } from "vuex";

export default {
  name: "Footer",
  mixins: [globalFunctions],

  data() {
    return {};
  },
  computed: {
    ...mapState(["url"]),
  },
  methods: {},
  mounted() {},
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
