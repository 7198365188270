<template>
    
    <router-link class="backbtn" to="/Servicios">
        <i class="bi bi-arrow-left-circle-fill"></i> Regresar
    </router-link>

</template>

<script>
export default {
  name: "separador",
  data() {
    return {};
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
